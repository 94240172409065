<template>
	<main id="filtres">
		<HeaderTab :title="$t('filtres.gestion_filtres')" />

	    <div id="content">
	        <div class="container-fluid">
	            <div class="row">
	                <div class="col-12">
                        <form @submit.prevent="() => { return false }"> <!-- On bloque la soumission du formulaire -->
    	                    <div class="box">
    	                    	<div class="row">
    	                    		<div class="col-12 col-6">
		                                <!-- Menu déroulant de sélection de la table (type de config) -->
										<label>{{ $t('table.general.type_tableau') }}</label>
                                        <e-select
                                                :options="all_view_type"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="true"
                                                :search="true"
                                                @select="switchConfigType"
                                                :value="selected_view_type"
                                            >
                                            <template slot="singleLabel" slot-scope="props">
                                                {{ $t('table.tables.'+props.option) }}
                                            </template>

                                            <template slot="option" slot-scope="props">
                                                {{ $t('table.tables.'+props.option) }}
                                            </template>
                                        </e-select>
		                            </div>
									<div class="col-12 col-6">
		                                <!-- Menu déroulant de sélection de la config (vue personnalisée) -->
		                                <div>
											<label>{{ $t('table.general.select_vue') }}</label>
                                            <e-select
                                                    track-by="id"
			                                        label="libelle"
                                                    :options="user_configs"
                                                    :searchable="true"
                                                    :allow-empty="false"
                                                    :show-labels="true"
                                                    :search="true"
                                                    @select="switchConfigView"
                                                    :value="selected_view_config"
                                                >
                                            </e-select>
		                                </div>
		                            </div>
	                            </div>
                            </div>

                            <!-- Edition de la vue -->
                            <div class="box" v-if="selected_view_config && selected_view_config.code">
                                <div class="row" v-if="selected_view_config.id">
                                    <div class="col-auto ml-auto d-flex">
                                        <a href="" class="btn ml-3 btn-secondary" @click.prevent="generateLink">
                                            <i><font-awesome-icon v-if="processing_share" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'link']" /></i> {{ $t('filtres.generate_link') }}
                                        </a>
                                    </div>
                                </div>

                                <!-- Champ d'édition du titre -->
    	                    	<div class="form-group ">
                        	        <label>{{ $t('filtres.nom_filtre') }}</label>
                        	        <input type="text" v-on:keydown.enter.prevent="" class="form-control" :placeholder="$t('filtres.ex_nom_filtre')" v-model="selected_view_config.libelle">
                        	    </div>

                                <!-- Vue par défaut pour ce tableau -->
                    	    	<b-form-checkbox 
                                    value="1"
                                    unchecked-value="0"
                                    v-model="selected_view_config.default">
                    	    	    {{ $t('filtres.definir_par_defaut') }}
                    	    	</b-form-checkbox>

        						<h3>{{ $t('filtres.colonnes') | capitalize }}</h3>

								<!-- Zone du choix du nombre de colonnes sur mobile -->
								<div class="form-group">
									<label>{{ $t('filtres.nb_colonnes_mobile') }}</label>
									<b-input type="number" v-model="selected_view_config.mobile_columns" />
								</div>

                                <label>{{ $t('filtres.nb_displayed_lines') }}</label>
                                <b-form-select
                                    v-model="selected_view_config.displayed_lines"
                                    class="custom-select mb-3"
                                >
                                    <option v-for="(selectNb, indexNb) in elementsInPages" :key="indexNb" :value="selectNb">
                                        {{ selectNb }}
                                    </option>
                                </b-form-select>

								<template v-if="allAvailableColumns.length > 0">
									<!-- Zone d'édition des colonnes -->
									<ConfigViewColumn
										:table_type="selected_view_type"
										:available_cols.sync="available_cols"
										:selected_columns.sync="selected_view_config.columns"
									/>

									<!-- Zone de choix du tri par défaut -->
									<ConfigSortColumn
										:table_type="selected_view_type"
										:columns="allAvailableColumns"
										:sort_by.sync="selected_view_config.sort_by"
										:sort_desc.sync="selected_view_config.sort_desc"
									/>

									<!-- Zone d'édition des filtres -->
									<ConfigFilterColumn
										:table_type="selected_view_type"
										:columns="allAvailableColumns"
										:filters.sync="selected_view_config.filters"
                                        :type_search="typeSearch"
                                        :table_id="selected_view_config.id"
									/>
								</template>

                                <div class="form-group mb-5">
                        	        <label>{{ $t('filtres.regroupement') }}</label>

                                    <e-select
                                        :options="allAvailableColumnsTrad"
                                        :searchable="true"
                                        allow-empty
                                        track-by="code"
                                        label="label"
                                        :show-labels="true"
                                        :search="true"
                                        :placeholder="$t('filtres.selectionnez_colonne')"
                                        v-model="selected_view_config.group_by"
                                    />
                        	    </div>

                                <!-- Zone des contrôles (boutons d'action) -->
    							<div class="text-center mb-3 mt-5">
                                    <!-- Bouton de sauvegarde -->
    								<button @click="saveData" class="btn btn-primary rounded-pill mr-3" :class="{ disabled: !hasUnsavedData || !canSubmitForm }">
                                            {{ $t('global.sauvegarder') }}
                                            <font-awesome-icon :pulse="is_saving" :icon="is_saving ? ['fa', 'spinner'] : ['fal', 'save']" class="ml-1"/>
                                    </button>
                                    <!-- Bouton de suppression -->
                                    <button v-if="canDeleteConfig" @click="deleteConfig" class="btn btn-secondary rounded-pill">
                                        {{ $t('global.supprimer') }}
                                        <font-awesome-icon :icon="['fal', 'trash-alt']" class="ml-1"/>
                                    </button>
    							</div>

    	                    </div>
                        </form>
	                </div>

                    <!-- Boite de dialogue de confirmation avant suppression -->
                    <ModalConfirm
                        ref="confirm_del_config"
                        id="confirm_del_config_c"
                        :icon="['fal', 'long-arrow-left']"
                        :text_titre="$t('modal.config_table.titre_suppression')"
                        :text_question="$t('modal.config_table.demande_confirmation_suppression')"
                        :text_button_ok="$t('global.supprimer')"
                        :text_button_cancel="$t('global.annuler')"
                        :callback_validation="confirmDeleteModal"
                    />

					<ModalConfirm
						ref="confirm_switch_config"
						id="confirm_switch_config_c"
						:icon="['fa', 'save']"
						:icon_accept="['fal', 'level-down']"
						:text_title="$t('modal.config_table.titre_switch_config')"
						:text_question="$t('modal.config_table.demande_sauvegarde_modif_attente')"
						:text_button_ok="$t('modal.general.sauvegarder')"
						:text_button_accept="$t('modal.general.ok')"
						:text_button_cancel="$t('modal.general.annuler')"
						:callback_validation="confirmSwitchSaveConfigModal"
						:callback_accept="confirmSwitchLooseConfigModal"
					/>

                    <b-modal ref="modalShare" hide-footer>
                        <template v-slot:modal-title>
                            {{ $t("filtres.lien_partage") }}
                        </template>

                        <b-input-group>
                            <b-form-input :disabled="true" v-model="link"></b-form-input>
                            <b-input-group-append>
                                <b-button @click="copyURL(link)"><font-awesome-icon :icon="['far', 'copy']" /></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-modal>
	            </div>
	        </div>
	    </div>
    </main>
</template>


<script type="text/javascript">
    import TableMixin from '@/mixins/Table.js'
    import UserMixin from '@/mixins/User.js'
    import Tools from '@/mixins/Tools.js'
	import diffArray from 'lodash/difference.js'
	import _sortBy from 'lodash/sortBy'
    import Vue from 'vue'

	export default {
		name: "TableConfigFilter",
        mixins: [TableMixin, UserMixin, Tools],
		data() {
            return {
                table_configs: [],       // Toutes les configs par défaut et utilisateur enregistrées (type -1, 0, et 1)
                all_view_type: [],       // Toutes les catégories de vues configurables
                selected_view_type: 0,   // La config type séléctionnée (0 si pas choisie, table_cle sinon)
                selected_view_config: null, // La config de vue personnalisée séléctionnée
                user_configs: [], // Les configs utilisateur pour un selected_view_type
                config_id: '',    // Si on a une config donnée en param de la route
                from: '',         // Nom de la route de laquelle on vient
                from_params: null,

                available_cols: [], // Les colonnes dispo restantes (allAvailableColumns sans les colonnes utilisées dans la vue en cours)
                config_backup: null,// Une copie de la config en cours d'édition. Permet de savoir si la config a été modifiée

                is_saving: false, // Booléen de verrou, si la sauvegarde est en cours

                switch_view_type: 0,      // La config type séléctionnée, mise de côté si on a une modale de confirmation
                switch_view_config: null, // La config de vue personnalisée séléctionnée, mise de côté si on a une modale de confirmation
                processing_share: false,
                link: null,
                share_id: null,
                elementsInPages: [
                    10,
                    50,
                    100,
                    200,
                    500,
                    1000
                ]
			}
	    },
        created() {
            this.config_id = this.$route.params.config_id
            this.share_id = this.$route.params.share_id
            this.from = this.$route.params.from
            this.getAllConfigs()
            this.getRedirectParams()
        },
        mounted() {
            // On charge la liste des colonnes dispos
            this.init_component()
        },
		methods: {
            async init_component() {
                const user_accesses = await this.getUserAccessRights()

				let all_view_type = []
                
                // On récupère les clés de tables configurable par rapport à cette gamme de licence
                // Pour chaque clé de type de config
                Object.keys(this.table_configs).forEach(type => {
                    // On récupère la 1ere configuration par défaut
                    const config = this.table_configs[type][0][0]

                    // Si pas de code de gamme
                    if(!config.module_access_code) {
						all_view_type.push(type)
                        return
                    }
                    // Si un code de gamme, on regarde si la licence actuelle y a souscrit
                    if(user_accesses.indexOf(config.module_access_code) > -1) {
                        all_view_type.push(type)
                    }
                })

				this.all_view_type = this.sortViewType(all_view_type)

                // Si on a un param table_cle dans la route, on vérifie qu'il existe, et si oui, on auto switch dessus
                const param_table_cle = this.$route.params.table_cle
                if(param_table_cle && this.all_view_type.indexOf(param_table_cle) > -1) {
                    this.switchConfigType(param_table_cle)
                }

                if(this.share_id) {
                    this.loadShareConfig()
                }
            },
            /* Récupére du localstorage, toutes les config enregistrées */
            getAllConfigs() {
                this.table_configs = this.getConfig("table_config")
            },
            /* Récupére, à partir de this.table_configs, toutes les vues pour le type (table_cle) actuel */
            getUserConfigs() {
                const table_cle = this.selected_view_type
                this.user_configs = [this.defaultConfig]
                this.user_configs = this.user_configs.concat(this.table_configs[table_cle][1])
            },
            /* Lorsque l'on change de type de configuration - chargement des vues perso pour ce type */
            switchConfigType(table_cle) {
                // Si on a déjà chargé une config, et qu'elle a été modifiée sans l'enregistrer
                if(this.selected_view_config && this.hasUnsavedData) {
                    this.switch_view_type = table_cle
                    this.$refs.confirm_switch_config.openModal()
                    return
                }
                this.selected_view_type = table_cle
                this.getUserConfigs()

                // Si on édite une config donnée par la route, on l'auto select
                if(this.config_id) {
                    const config = this.user_configs.find(config => config.id == this.config_id)
                    this.switchConfigView(config, true)

                    // On raz this.config_id pour ne pas repasser dans cette condition, au prochain switch
                    this.config_id = null
                }
                // Sinon, on auto select par défaut
                else {
                    // On auto select la config mise par défaut, si elle existe
                    const default_config = this.user_configs.find(config => config.default === 1)
                    
                    if(default_config) {
                        this.switchConfigView(default_config, true)
                    }
                    // Sinon, on auto select la 1ere config 
                    else {
                        this.switchConfigView(this.user_configs[0], true)
                    }
                }
            },
            /* Lorsque l'on change de vue personnalisée - chargement de la config : colonnes, filtre, group_by */
            switchConfigView(config, without_check=false) {
                // On vérifie si des modifs n'ont pas été sauvegardées
                if(!without_check && this.hasUnsavedData) {
                    this.switch_view_config = config
                    this.$refs.confirm_switch_config.openModal()
                    return
                }
                if(config.group_by) {
                    config.group_by = this.allAvailableColumnsTrad.find(col => col.code == config.group_by)
                }
                // Sinon, on la prend
                this.selected_view_config = config

                // On switch
                this.loadConfigSetting(config)
                this.backupActualConfig()
            },  
            /* Précharge le formualire à partir de la vue */
            loadConfigSetting(config) {
                const used_cols = config.columns
                this.available_cols = diffArray(this.allAvailableColumns, used_cols)
            },
            /* Garde de côté la version initiale de la config. Permet de savoir si la config a été modifiée */
            backupActualConfig() {
                this.config_backup = JSON.stringify(this.selected_view_config)
            },
            /* Formattage du formulaire envoyé pour la sauvegarde de la config */
            formatDataSave() {
                return {
                    'columns' : this.selected_view_config.columns,
                    'filters' : this.selected_view_config.filters,
                    'libelle' : this.selected_view_config.libelle,
                    'default_view': this.selected_view_config.default,
                    'table_code'  : this.selected_view_type,
					'group_by': this.selected_view_config.group_by ? this.selected_view_config.group_by.code : '',
					'sort_by': this.selected_view_config.sort_by || '',
					'sort_desc': this.selected_view_config.sort_desc || false,
					'mobile_columns': this.selected_view_config.mobile_columns || 3,
                    'displayed_lines': this.selected_view_config.displayed_lines || 50
                }
            },
            /* Enregistrement de la config, ou de ses modifs */
            async saveData() {
                if(this.is_saving || !this.hasUnsavedData) return

                if(!this.canSubmitForm) {
                    this.failureToast('toast.info_donnees_manquantes')
                    return false
                }
                this.is_saving = true
                
                const data = this.formatDataSave()
                let result = null

                // Si c'est une update d'une vue
				if(this.selected_view_config.id > 0 && !this.share_id) {
					result = await this.updateTableConfig(this.selected_view_config.id, data)
                }
                // Si c'est la création d'une nouvelle vue
                else {
                    result = await this.addTableConfig(data)
                }

                await this.postSaveUpdates(result)
                this.is_saving = false
            },
            /* Traitement après la sauvegarde de la config - on reload le localstorage, et on reload le contenu de la page */
            async postSaveUpdates(result, type='default') {
                if(result && result.code_retour == 0 && result.retour) {
                    this.successToast('toast.info_modif_succes')
                    const config_id = result.retour.lastId

                    // On reload la config
                    await this.loadTableConfig()

                    // On la récupère dans l'instance
                    this.getAllConfigs()
                    this.getUserConfigs()

					// Si on vient de faire une suppression, on reset le form
					if(type == 'delete') {
                        this.selected_view_config = null
                        this.switchConfigType(this.selected_view_type)
                    }
                    // Si on a fait un insert/update, on reload la config à partir du local storage, pour être sur que la modif a bien été prise en compte en distant
					else {
                        const config = this.user_configs.find(config => config.id == config_id)
                        this.switchConfigView(config, true)
					}

                    // On fait la redirection si on vient d'un tableau par ex
					if(this.from) this.$router.push({ name: this.from, params: this.from_params || {} })
                }
                else {
                    this.failureToast('toast.info_save_failed')
                }
            },

            /* Suppression de config : ouverture de la modale de suppression. Sa callback exécute la suppression */
            deleteConfig() {
                this.$refs.confirm_del_config.openModal()
            },

			/* MODALES */
			async confirmSwitchSaveConfigModal() {
				if(this.canSubmitForm) {
					const result = await this.saveData()
					this.postSaveUpdates(result)
					this.switchConfig(this.selected_view)
				}
				else {
					this.failureToast('toast.info_donnees_manquantes')
				}

				this.$refs.confirm_switch_config.closeModal()
			},

			confirmSwitchLooseConfigModal() {
                // Si c'est un changement de type de vue (table_cle)
                if(this.switch_view_type !== 0) {
                    // On raz la vue sélectionnée, pour ne pas repasser dans le check hasUnsavedData de loadConfigType
                    this.selected_view_config = null

                    // On fait le changement
                    this.switchConfigType(this.switch_view_type)
                    this.switch_view_type = 0
					this.$refs.confirm_switch_config.closeModal()
                }
                // Si c'est un changement de vue personnalisée
                else if(this.switch_view_config) {
                    // On fait le changement
					this.switchConfigView(this.switch_view_config, true)
                    this.switch_view_config = null
					this.$refs.confirm_switch_config.closeModal()
				}
			},

            /* Callback de la modale de suppression */
			async confirmDeleteModal() {
                const config_id = this.selected_view_config.id
				const result = await this.deleteTableConfig(config_id)

				this.postSaveUpdates(result, 'delete')
				this.$refs.confirm_del_config.closeModal()
				window.scrollTo(0, 0)
            },
            
            /* Recup des éventuels params initialisés par la vue précédente pour la redirection après sauvegarde */
            getRedirectParams() {
                const params_for_redirect = this.$store.state.redirectParams

                if(params_for_redirect && Object.keys(params_for_redirect).length > 0) {
                    this.from_params = params_for_redirect
                    this.$store.commit({ type: 'unsetRedirectParams' })
                }
            },

            sortViewType(view_type) {
                // let order_view = ["horse_ajout", "horse", "choix_horse", "horse_planning", "mouvements_horse", "horse_actes", "horse_ordonnances", "tiers_horse_tiers", "tiers_horse_historique_tiers", "horse_pension", "horse_pension_historique", "horse_actes_gyneco", "contacts", "season_mare_stallion", "horse_stat_fertilite", "planning", "liste_actes", "mouvements", "mouvements_residence", "carnet_adresse", "ordonnances", "registry_history", "tiers", "contacts", "tiers_horse_horse", "tiers_horse_historique_horse", "tiers_invoices", "tiers_payment", "invoices", "facturation_a_valider", "bons", "impayes", "payment", "check_deposit", "jumenterie", "statistiques_repro", "fertilite", "accounting", "model", "accounting_account", "articles", "actes_type_articles", "check_deposit_configuration", "export_perso_config", "acte_type", "trigger_entity", "season", "addressed_invoices_to_pay", "addressed_invoices_sold", "addressed_payment", "localisation", "qualif_config", "qualif_fiche", "qualif_horse", "qualif_tiers", "contract_config", "contract_config_type_monte", "contract", "contract_tiers", "avenant_articles", "horse_syndic_details", "historique_syndic_etalon", "qualif_fiche", "bilan_saison"];

                // view_type.sort((view_a, view_b) => {
                //     if (order_view.indexOf(view_a) < order_view.indexOf(view_b)){
                //         return -1;
                //     }
                //     if (order_view.indexOf(view_a) > order_view.indexOf(view_b)){
                //         return 1;
                //     }
                //     return 0;
                // })

				// return view_type.filter(view => order_view.indexOf(view) > -1);
				// return _sortBy(view_type)
				return view_type
            },

            async generateLink() {
                this.processing_share = true

                const id = await this.generateTableConfigShareLink(this.selected_view_config.id)
                let props = this.$router.resolve({ 
                    name: 'tableConfigFilter',
                    params: { share_id: id },
                });
                this.link = window.location.origin + "/" + props.href

                this.$refs.modalShare.show()
                this.processing_share = false
            },

            async loadShareConfig() {
                const config = await this.getShareConfig(this.share_id)

                this.switchConfigType(config.code)
                if(config.sort_desc == 0) {
                    config.sort_desc = false
                }
                if(config.sort_desc == 1) {
                    config.sort_desc = true
                }
                this.selected_view_config = config
                this.loadConfigSetting(config)
            },

            async copyURL(mytext) {
                try {
                    await navigator.clipboard.writeText(mytext);
                } catch($e) {
                    console.error($e);
                }
            }
		},
		computed: {
            // Toutes les configs pour un type de vue (pour une table_cle)
            typeConfigs() {
                if(this.selected_view_type == 0) return null
                return this.table_configs[this.selected_view_type]
            },
            // Utilisé pour créer une nouvelle vue vierge
			defaultConfig() {
                let time = new Date().toLocaleString(Vue.prototype.$i18n.locale()).split(' ')[1]
				return {
					id: 0,
					code: this.selected_view_type,
					user: 1,
					columns: this.typeConfigs[0][0].columns,
					filters: [],
					default: false,
					sort_by: null,
					sort_desc: false,
					libelle: this.getTrad('table.general.nouvelle_vue') + ' - ' + this.formatDate(new Date()) + ' ' + time,
					mobile_columns: 3,
                    displayed_lines: 50
				}
            },
            // La liste des colonnes utilisables pour ce type de config (ce table_cle)
            allAvailableColumns() {
                return this.typeConfigs ? this.typeConfigs[-1][0].columns : []
            },
            allAvailableColumnsTrad() {
                if(!this.typeConfigs) {
                    return []
                }
                let columns = this.typeConfigs[-1][0].columns.map(col => ({
                    code: col,
                    label: this.getColumnTrad(this.selected_view_type, col)
                }))
                return columns
            },
            // Les conditions de validation d'un formulaire
            canSubmitForm() {
                if(!this.selected_view_config) return false

                const cond_name    = this.selected_view_config.libelle !== ''
                const cond_columns = this.selected_view_config.columns.length > 0
                return cond_name && cond_columns
            },
            // Condition de suppression de la config
            canDeleteConfig() {
                return this.selected_view_config.id > 0
            },
            // Vérificaton de la modification de la config ( si la config a été éditée )
            hasUnsavedData() {
                return JSON.stringify(this.selected_view_config) != this.config_backup
            },

            typeSearch() {
                const config = this.typeConfigs
                if(!config) return null
                if(config[-1][0].columns_search.length == 0) return null
                return config[-1][0].columns_search
            }
		},
        components: {
			HeaderTab: () => import("@/components/HeaderTab"),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			ConfigViewColumn: () => import('GroomyRoot/components/Table/TableConfigColumnViewV2'),
			ConfigFilterColumn: () => import('GroomyRoot/components/Table/TableConfigColumnFilterV2'),
			ConfigSortColumn: () => import('GroomyRoot/components/Table/TableConfigColumnSort'),
        }
	}
</script>
